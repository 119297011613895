import {
  Card,
  Stack,
  Table,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { isNil } from 'lodash';
import { safeMultiply } from 'shared/math';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorBanner from '../../../../../common/components/error-banner';
import {
  OrderConsolidationMethod,
  type PreviewProratedConsolidationV2Output,
  type ProratedOrderV2Fragment,
  type ProratedShipmentV2Fragment,
  type PreviewProratedConsolidationV2SuccessOutput,
  FreightBillingMethod,
  FuelBillingMethod,
} from '../../../../../generated/graphql';
import {
  TableCellCustom,
  TableHeadCustom,
  TableHeaderTypography,
  TableRowCustom,
  TableTypography,
} from './common';
import { exhaustive } from 'shared/switch';
import { sentenceCase } from 'change-case';

const convertConsolidationMethodToText = (
  consolidationMethod: OrderConsolidationMethod,
  order: ProratedOrderV2Fragment,
  totalOrderCount: number,
  totalWeight: number,
) => {
  switch (consolidationMethod) {
    case OrderConsolidationMethod.ByWeight: {
      return `${order.weight} of ${totalWeight} lbs`;
    }
    case OrderConsolidationMethod.EvenDistribution: {
      return `1 of ${totalOrderCount} orders`;
    }
    case OrderConsolidationMethod.BaseOrder: {
      return 'All charges on base order';
    }
    default: {
      return exhaustive(consolidationMethod);
    }
  }
};
const BreakdownOfChargesAfterProrate = ({
  inboundShipmentInfo,
  outboundShipmentInfo,
  order,
  consolidationMethod,
  totalOrderCount,
  totalWeight,
}: {
  readonly inboundShipmentInfo: ProratedShipmentV2Fragment | null;
  readonly outboundShipmentInfo: ProratedShipmentV2Fragment | null;
  readonly order: ProratedOrderV2Fragment;
  readonly consolidationMethod: OrderConsolidationMethod;
  readonly totalOrderCount: number;
  readonly totalWeight: number;
}) => {
  const theme = useTheme();

  return (
    <Stack bgcolor={theme.palette.concreteGrey[10]}>
      <Table>
        {isNil(inboundShipmentInfo) && isNil(outboundShipmentInfo) ? (
          <TableCellCustom sx={{ width: '100%' }}>
            <TableTypography isGrey align="center">
              Order is missing shipment information
            </TableTypography>
          </TableCellCustom>
        ) : (
          <TableRowCustom addBorderTop>
            <TableCellCustom cellPadding={6} sx={{ width: '50%' }}>
              <TableTypography bold>{order.hawb}</TableTypography>
              <TableTypography color={theme.palette.concreteGrey[50]}>
                {order.name}
              </TableTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={6} sx={{ width: '30%' }}>
              <TableTypography>
                {isNil(order.proportionForConsolidation)
                  ? '-'
                  : `${safeMultiply(order.proportionForConsolidation, 100)}%`}
              </TableTypography>
              <TableTypography color={theme.palette.concreteGrey[50]}>
                {convertConsolidationMethodToText(
                  consolidationMethod,
                  order,
                  totalOrderCount,
                  totalWeight,
                )}
              </TableTypography>
            </TableCellCustom>
            <TableCellCustom
              cellPadding={6}
              sx={{ width: '20%', textAlign: 'right' }}
            >
              ${order.totalAfterProrating.toFixed(2)}
            </TableCellCustom>
          </TableRowCustom>
        )}
      </Table>
    </Stack>
  );
};

const ProratedOrderDetails = ({
  consolidationOutput,
}: {
  readonly consolidationOutput:
    | PreviewProratedConsolidationV2Output
    | undefined;
}) => {
  const theme = useTheme();

  if (isNil(consolidationOutput?.__typename)) {
    return null;
  }
  if (consolidationOutput.__typename === 'QueryErrorOutput') {
    return <ErrorBanner errorMessage={consolidationOutput.message} />;
  }
  if (
    consolidationOutput.__typename ===
    'PreviewProratedConsolidationV2SuccessOutput'
  ) {
    const { proratedOrders, totalAfterProrating, proratedOverview } =
      consolidationOutput;

    return (
      <Card sx={{ height: '70vh', overflow: 'scroll' }}>
        <Stack bgcolor={theme.palette.concreteGrey[20]} height="100%">
          <Stack
            px={2}
            py={1.5}
            borderBottom={`1px solid ${theme.palette.concreteGrey[30]}`}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" fontSize={16}>
                Review consolidated orders
              </Typography>
            </Stack>
          </Stack>
          <Stack flexGrow={1} height="100%" mt={1.5}>
            <Stack marginX={1} bgcolor={theme.palette.concreteGrey[20]}>
              <Table>
                <TableHeadCustom>
                  <TableCellCustom sx={{ width: '50%' }}>
                    <TableHeaderTypography sidePadding={4}>
                      Order
                    </TableHeaderTypography>
                  </TableCellCustom>
                  <TableCellCustom sx={{ width: '30%' }}>
                    <TableHeaderTypography>
                      Distribution rate
                    </TableHeaderTypography>
                  </TableCellCustom>
                  <TableCellCustom sx={{ width: '20%', textAlign: 'right' }}>
                    <TableHeaderTypography>Amount</TableHeaderTypography>
                  </TableCellCustom>
                </TableHeadCustom>
              </Table>
              {proratedOrders?.map((proratedOrder) => {
                return (
                  <BreakdownOfChargesAfterProrate
                    key={proratedOrder.uuid}
                    inboundShipmentInfo={proratedOrder.inboundShipment ?? null}
                    outboundShipmentInfo={
                      proratedOrder.outboundShipment ?? null
                    }
                    order={proratedOrder}
                    consolidationMethod={
                      consolidationOutput.consolidationMethod
                    }
                    totalOrderCount={proratedOrders.length}
                    totalWeight={consolidationOutput.totalWeightConsolidated}
                  />
                );
              })}
            </Stack>
            <Stack marginX={1} marginTop={1}>
              <Accordion elevation={0}>
                <AccordionSummary sx={{ padding: 0, margin: 0 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    paddingX={1}
                  >
                    <Stack direction="row" alignItems="center">
                      <ExpandMoreIcon />
                      <Typography fontWeight={600}>
                        Consolidated total
                      </Typography>
                    </Stack>
                    <Typography fontWeight={600} fontSize={14}>
                      ${totalAfterProrating.toFixed(2)}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  {!isNil(proratedOverview) && (
                    <Table>
                      <TableHeadCustom>
                        <TableCellCustom>
                          <TableHeaderTypography>
                            Breakdown
                          </TableHeaderTypography>
                        </TableCellCustom>
                        <TableCellCustom>
                          <TableHeaderTypography>Rate</TableHeaderTypography>
                        </TableCellCustom>
                        <TableCellCustom align="right">
                          <TableHeaderTypography>Qty</TableHeaderTypography>
                        </TableCellCustom>
                        <TableCellCustom align="right">
                          <TableHeaderTypography>Amount</TableHeaderTypography>
                        </TableCellCustom>
                      </TableHeadCustom>
                      {!isNil(proratedOverview.inboundFreightCharge) && (
                        <TableRowCustom>
                          <TableCellCustom>Pickup freight</TableCellCustom>
                          <TableCellCustom>
                            {sentenceCase(
                              proratedOverview.inboundFreightCharge
                                .billingMethod,
                            )}
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              display="block"
                            >
                              ${proratedOverview.inboundFreightCharge.rate}
                              {proratedOverview.inboundFreightCharge
                                .billingMethod ===
                                FreightBillingMethod.Weight && '/100 lbs'}
                            </Typography>
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            {proratedOverview.inboundFreightCharge.quantity}
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            ${proratedOverview.inboundFreightCharge.amount}
                          </TableCellCustom>
                        </TableRowCustom>
                      )}
                      {!isNil(proratedOverview.inboundFuelCharge) && (
                        <TableRowCustom>
                          <TableCellCustom>Pickup fuel</TableCellCustom>
                          <TableCellCustom>
                            {sentenceCase(
                              proratedOverview.inboundFuelCharge.billingMethod,
                            )}
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            {proratedOverview.inboundFuelCharge
                              .billingMethod === FuelBillingMethod.FlatRate
                              ? '-'
                              : `${proratedOverview.inboundFuelCharge.quantity}%`}
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            ${proratedOverview.inboundFuelCharge.amount}
                          </TableCellCustom>
                        </TableRowCustom>
                      )}
                      {!isNil(proratedOverview.outboundFreightCharge) && (
                        <TableRowCustom>
                          <TableCellCustom>Delivery freight</TableCellCustom>
                          <TableCellCustom>
                            {sentenceCase(
                              proratedOverview.outboundFreightCharge
                                .billingMethod,
                            )}
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              display="block"
                            >
                              ${proratedOverview.outboundFreightCharge.rate}
                              {proratedOverview.outboundFreightCharge
                                .billingMethod ===
                                FreightBillingMethod.Weight && '/100 lbs'}
                            </Typography>
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            {proratedOverview.outboundFreightCharge.quantity}
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            ${proratedOverview.outboundFreightCharge.amount}
                          </TableCellCustom>
                        </TableRowCustom>
                      )}
                      {!isNil(proratedOverview.outboundFuelCharge) && (
                        <TableRowCustom>
                          <TableCellCustom>Delivery fuel</TableCellCustom>
                          <TableCellCustom>
                            {sentenceCase(
                              proratedOverview.outboundFuelCharge.billingMethod,
                            )}
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            {proratedOverview.outboundFuelCharge
                              .billingMethod === FuelBillingMethod.FlatRate
                              ? '-'
                              : `${proratedOverview.outboundFuelCharge.quantity}%`}
                          </TableCellCustom>
                          <TableCellCustom align="right">
                            ${proratedOverview.outboundFuelCharge.amount}
                          </TableCellCustom>
                        </TableRowCustom>
                      )}
                      {!isNil(proratedOverview.customCharges) &&
                        proratedOverview.customCharges.map((customCharge) => (
                          <TableRowCustom key={customCharge.name}>
                            <TableCellCustom>
                              {customCharge.name}
                            </TableCellCustom>
                            <TableCellCustom>Custom charge</TableCellCustom>
                            <TableCellCustom align="right">1</TableCellCustom>
                            <TableCellCustom align="right">
                              ${customCharge.amount.toFixed(2)}
                            </TableCellCustom>
                          </TableRowCustom>
                        ))}
                    </Table>
                  )}
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    );
  }

  return null;
};

export default ProratedOrderDetails;
